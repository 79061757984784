import React from 'react'
import CardFrontDiana from './images/FrontDiana.png'
import CardFrontJulianna from './images/FrontJulianna.png'
import CardFrontMartyna from './images/FrontMartyna.png'
import PhotoDiana from './images/DianaWalczakBogusz.jpg'
import PhotoJulianna from './images/JuliannaKedzierska.jpg'
import PhotoMartyna from './images/MartynaPereta.jpg'
import PhotoDiana2 from './images/DianaWalczakBogusz2.jpg'
import PhotoJulianna2 from './images/JuliannaKedzierska2.jpg'
import PhotoMartyna2 from './images/MartynaPereta2.jpg'
import DWBLogo from './DWB.svg'
import JKLogo from './JK.svg'
import MPLogo from './MP.svg'
import PrawoKarne from './icons/prawokarne.inline.svg'
import PrawoHandlowe from './icons/handlowe.inline.svg'
import ObslugaPrawnaPrzedsiebiorcow from './icons/obslugaprawnaprzedsiebiorcow.inline.svg'
import PrawoAdministracyjne from './icons/prawoadministracyjne.inline.svg'
import PrawoCywilne from './icons/prawocywilne.inline.svg'
import PrawoWyznanione from './icons/pwyznaniowepkanoniczne.inline.svg'
import PrawoRodzinne from './icons/rodzinne.inline.svg'
import PrawoPracy from './icons/prawopracy.inline.svg'
import StwierdzenieNiewaznosiMalzenstwa from './icons/stwierdzenieniewaznoscimalzenstwa.inline.svg'

export const lawyers = [
  {
    id: 1,
    cardFront: CardFrontDiana,
    photo: PhotoDiana,
    photo2: PhotoDiana2,
    logo: DWBLogo,
    name: 'Diana Walczak-Bogusz',
    slug: 'diana-walczak-bogusz',
    prefix: 'Adwokat',
    phone: '+48 691 507 853',
    email: 'kancelaria@adwokatdwb.pl',
    website: 'adwokatdwb.pl',
    description:
      'Członek adwokatury od 2016r., absolwentka Wydziału Prawa i Administracji Katolickiego Uniwerystetu Lubelskiego. Doświadczenie zawodowe zdobywała w renomowanych kancelariach prawnych na terenie Lublina. Specjalizuje się w prawie karnym, cywilnym, gospodarczym w zakresie odszkodowań oraz windykacji należności. Zajmuje się obsługą prawną przedsiębiorców i doradztwem na rzecz osób fizycznych. Praca adwokata jest jej profesją i życiową pasją, a nadrzędną wartością ludzie, którzy powierzają sprawy wymagające prawniczego wsparcia.',
    nip: '7133024049',
    regon: '384207410',
  },
  {
    id: 2,
    cardFront: CardFrontJulianna,
    photo: PhotoJulianna,
    photo2: PhotoJulianna2,
    logo: JKLogo,
    name: 'Julianna Kędzierska',
    slug: 'julianna-kedzierska',
    prefix: 'Adwokat',
    phone: '+48 500 099 376',
    email: 'julianna.kedzierska@gmail.com',
    website: 'juliannakedzierska.pl',
    description:
      'Członek adwokatury od 2016r., absolwentka prawa na Uniwerystecie Marii Curie-Skłodowskiej w Lublinie. Praktykę zawodową zdobywała już w trakcie studiów współpracując z renomowanymi kancelariami lubelskimi. W okresie aplikacji adwokackiej współpracowała z Kancelarią AKP Adwokaci (Adw. Michał Adach, Adw. Eliwra Kister, Adw. Piot Pietraszko) w Lublinie. Specjalizuje się przede wszystkim w zakresie prawa rodzinnego i opiekuńczego (w szczególności rozwody, alimenty, władza rodzicielska, kontakty z małoletnim dzieckiem itp.), cywilnego, prawa karnego oraz prawa gospodarczego.',
    nip: '9462583101',
    regon: '384207083',
  },
  {
    id: 3,
    cardFront: CardFrontMartyna,
    photo: PhotoMartyna,
    photo2: PhotoMartyna2,
    logo: MPLogo,
    name: 'Martyna Pereta',
    slug: 'martyna-pereta',
    prefix: 'Adwokat/Adwokat kościelny',
    phone: '+48 518 337 507',
    email: 'pereta.kancelaria@gmail.com',
    website: 'kancelariapereta.pl',
    description:
      'Członek adwokatury od 2016r., absolwentka Wydziału Prawa, Prawa kanonicznego i Administracji na Katolickim Uniwersytecie Lubelskim. Doświadczenie zawodowe zdobywała w kancelariach adwokackich na terenie apelacji lubelskiej i rzeszowskiej. Nieprzerwanie świadczy pomoc prawną w sprawach z zakresu prawa rodzinnego, cywilnego i karnego. Jako adwokat kościelny specjalizuje się w procesach kościelnych o stwierdzenie nieważności małżeństwa, jak również wszelkich innych sprawach z dziedziny prawa kanonicznego.',
    nip: '8652463411',
    regon: '381852825',
  },
]

export const services = [
  {
    lawyerId: 1,
    services: [
      {
        icon: <PrawoKarne />,
        title: 'Prawo Karne',
        slug: 'prawo-karne',
        scopeDetail: [
          'obrona podejrzanego w postępowaniu przygotowawczym',
          'obrona oskarżonego w postępowaniu sądowym',
          'obrona obwinionego w sprawach o wykroczenia',
          'obrona w sprawach o przestępstwa i wykroczenia skarbowe',
          'reprezentowanie pokrzywdzonego i oskarżyciela posiłkowego',
          'zawiadomienia o możliwości popełnienia przestępstwa',
          'prywatne akty oskarżenia',
          'sprawy związane z wypadkami drogowymi',
          'warunkowe przedterminowe zwolnienie z odbycia reszty kary',
          'odroczenie wykonania kary',
          'przerwa w wykonywaniu kary',
          'odbywanie kary w systemie dozoru elektronicznego',
        ],
      },
      {
        icon: <PrawoCywilne />,
        title: 'Prawo Cywilne',
        slug: 'prawo-cywilne',
        scopeDetail: [
          'odzyskiwanie należności',
          'odszkodowania',
          'zadośćuczynienia',
          'dochodzenie roszczeń związanych z wypadkami komunikacyjnymi',
          'projekty umów',
          'roszczenia wynikające z umów',
          'zasiedzenie',
          'ochrona własności',
          'ochrona dóbr osobistych',
          'droga konieczna i inne służebności',
          'postępowanie egzekucyjne',
          'powództwa o zapłatę',
          'windykacja',
          'sprawy majątkowe',
          'sprawy spadkowe (stwierdzenie nabycia spadku, dział spadku, zachowek)',
          'ugodowe rozwiązywanie sporów',
        ],
      },
      {
        icon: <PrawoAdministracyjne />,
        title: 'Prawo Administracyjne',
        slug: 'prawo-administracyjne',
        scopeDetail: [
          'odwołania od decyzji',
          'skargi do wojewódzkich sądów administracyjnych',
          'skargi kasacyjne do Naczelnego Sądu Administracyjnego',
        ],
      },
      {
        icon: <ObslugaPrawnaPrzedsiebiorcow />,
        title: 'Obsługa Prawna Przedsiębiorców',
        slug: 'obsluga-prawna',
        scopeDetail: [
          'obsługa spółek',
          'odzyskiwanie należności',
          'projekty umów',
          'opinie prawne',
          'porady prawne',
        ],
      },
    ],
  },
  {
    lawyerId: 2,
    services: [
      {
        icon: <PrawoRodzinne />,
        title: 'Prawo Rodzinne',
        slug: 'prawo-rodzinne',
        scopeDetail: [
          'rozwód (za porozumieniem stron, z winy)',
          'separacja (zgodna, sporna)',
          'alimenty (ustalenie obowiązku, alimentacyjnego, podwyższenie, obniżenie, zniesienie obowiązku alimentacyjnego)',
          'władza rodzicielska (ograniczenie, pozbawienie, zawieszenie)',
          'kontakty z dzieckiem',
          'zmiana ustalonych kontaktów z dzieckiem',
          'sprawy o zagrożenie grzywną w przypadku utrudniania lub niewykonywania kontaktów z dzieckiem',
          'podział majątku wspólnego',
          'ustalenie ojcostwa',
          'zaprzeczenie ojcostwa',
          'ustalenie bezskuteczności uznania ojcostwa',
          'ustanowienie rozdzielności majątkowej małżeńskiej',
          'związane z rozstrzygnięciem o istotnych sprawach rodziny',
        ],
      },
      {
        icon: <PrawoCywilne />,
        title: 'Prawo Cywilne',
        slug: 'prawo-cywilne',
        scopeDetail: [
          '(z zakresu prawa rzeczowego, zobowiązań oraz prawa spadkowego):',
          'zasiedzenie',
          'rozgraniczenie',
          'zniesienie współwłasności',
          'wynikające z zarządu rzeczą wspólną',
          'związane z ochroną własności',
          'o ustanowienie i zniesienie służebności (np. służebności drogi koniecznej i służebności przesyłu)',
          'o naruszenie posiadania',
          'sprawy z tytułu bezpodstawnego wzbogacenia',
          'o odszkodowanie i zadośćuczynienie z czynów niedozwolonych',
          'o odszkodowanie z tytułu niewykonania lub nienależytego wykonania umowy',
          'umowy (sprawy o zapłatę, przedawnione wierzytelności, e-Sąd, postępowanie upominawcze i nakazowe)',
          'odwołanie darowizny',
          'dziedziczenie',
          'zachowek',
          'odrzucenie spadku',
          'stwierdzenie nabycia spadku',
          'dział spadku',
          'ochrona dóbr osobistych i inne',
        ],
      },
      {
        icon: <PrawoKarne />,
        title: 'Prawo Karne',
        slug: 'prawo-karne',
        scopeDetail: [
          'obrona w postępowaniu przygotowawczym oraz w postępowaniu przed sądami powszechnymi wszystkich instancji (sporządzanie apelacji, kasacji i zażaleń)',
          'obrona skazanych w postępowaniu wykonawczym (dozór elektroniczny, odroczenie wykonania kary, przerwa w karze, warunkowe przedterminowe zwolnienie)',
          'reprezentowanie pokrzywdzonych w sprawach karnych',
          'porady prawne z zakresu prawa karnego, postępowania karnego i wykonawczego',
          'sporządzanie prywatnych aktów oskarżenia',
        ],
      },
      {
        icon: <PrawoAdministracyjne />,
        title: 'Prawo Administracyjne',
        slug: 'prawo-administracyjne',
        scopeDetail: [
          'porady i opinie prawne z zakresu prawa administracyjnego',
          'reprezentacja w postępowaniu przed organami i sądami administracyjnymi',
          'odwołania od decyzji',
          'skargi do wojewódzkich sądów administracyjnych',
          'skargi kasacyjne do Naczelnego Sądu Administracyjnego',
        ],
      },
      {
        icon: <PrawoPracy />,
        title: 'Prawo Pracy',
        slug: 'prawo-pracy',
        scopeDetail: [
          'dochodzenie zaległego wynagrodzenia',
          'spory o przywrócenie do pracy (zwolnienie dyscyplinarne, wypowiedzenie umowy o pracę)',
          'mobbing',
          'odszkodowanie',
          'wypadek w pracy',
        ],
      },
      {
        icon: <ObslugaPrawnaPrzedsiebiorcow />,
        title: 'Obsługa Prawna Przedsiębiorców',
        slug: 'obsluga-prawna',
        scopeDetail: [
          'windykacja wierzytelności – sporządzanie wezwań do zapłaty, sporządzanie pozwów i prowadzenie spraw sądowych, w tym w elektronicznym postępowaniu upominawczym, prowadzenie spraw w postępowaniu egzekucyjnym',
          'egzekucja długu',
        ],
      },
    ],
  },
  {
    lawyerId: 3,
    services: [
      {
        icon: <PrawoKarne />,
        title: 'Prawo Karne',
        slug: 'prawo-karne',
        scopeDetail: [
          'obrona podejrzanego w postępowaniu przygotowawczym',
          'obrona oskarżonego w postępowaniu sądowym',
          'obrona obwinionego w sprawach o wykroczenia',
          'obrona w sprawach o przestępstwa i wykroczenia skarbowe',
          'reprezentowanie pokrzywdzonego i oskarżyciela posiłkowego',
          'zawiadomienia o możliwości popełnienia przestępstwa',
          'prywatny akty oskarżenia',
          'sprawy związane z wypadkami drogowymi',
          'warunkowe przedterminowe zwolnienie z odbycia reszty kary',
          'odroczenie wykonania kary',
          'przerwa w wykonywaniu kary',
          'odbywanie kary w systemie dozoru elektronicznego',
          'prawo karne gospodarcze',
        ],
      },
      {
        icon: <PrawoCywilne />,
        title: 'Prawo Cywilne',
        slug: 'prawo-cywilne',
        scopeDetail: [
          'odzyskiwanie należności',
          'odszkodowania',
          'zadośćuczynienia',
          'dochodzenie roszczeń związanych z wypadkami komunikacyjnymi',
          'roszczenia wynikające z umów',
          'zasiedzenie',
          'ochrona własności',
          'ochrona dóbr osobistych',
          'droga konieczna i inne służebności',
          'postępowanie egzekucyjne',
          'powództwa o zapłatę',
          'sprawy majątkowe',
          'sprawy spadkowe (stwierdzenie nabycia spadku, dział spadku, zachowek)',
          'ugodowe rozwiązywanie sporów',
        ],
      },
      {
        icon: <PrawoRodzinne />,
        title: 'Prawo Rodzinne i Opiekuńcze',
        slug: 'prawo-rodzinne',
        scopeDetail: [
          'rozwód',
          'podział majątku',
          'alimenty',
          'władza rodzicielska',
          'kontakty z małoletnim',
          'separacja',
          'ustalenie i zaprzeczenie ojcostwa',
          'ustanowienie rozdzielności majątkowej małżeńskiej',
          'rozstrzyganie o istotnych sprawach rodziny',
        ],
      },
      {
        icon: <PrawoHandlowe />,
        title: 'Prawo Gospodarcze i Handlowe',
        slug: 'prawo-gospodarcze',
        scopeDetail: [
          'konsultację prawne',
          'opinie prawne',
          'windykacja należności',
          'projekty umów',
          'pomoc prawna na etapie tworzenia spółek',
          'doradztwo w zakresie doboru odpowiedniej formy prawnej spółki',
          'obsługa prawna procesu tworzenia i rejestracji spółki prawa handlowego   (spółka jawna, partnerska, komandytowa, komandytowo-akcyjnej, spółki z ograniczoną odpowiedzialnością i spółki akcyjnej)',
          'pomoc prawna na etapie bieżącej działalności statutowej spółki',
          'pomoc prawna na etapie likwidacji i przekształceń spółek',
          'obsługa prawna likwidacji spółki prawa handlowego',
          'reprezentacja procesowa w postępowaniach o uchylenie lub stwierdzenie nieważności uchwał Zgromadzenia Wspólników/Zgromadzenia Akcjonariuszy',
        ],
      },
      {
        icon: <StwierdzenieNiewaznosiMalzenstwa />,
        title: 'Sprawy o stwierdzenie nieważności małżeństwa',
        slug: 'niewaznosc-malzenstwa',
        scopeDetail: [
          'Dla stron powodowych:',
          'konsultacja sprawy przed rozpoczęciem procesu',
          'sporządzenie skargi powodowej',
          'rekursy przeciwko odrzuceniu skargi powodowej przez Trybunał',
          'pomoc w doborze materiału dowodowego',
          'redagowanie pism procesowych związanych z ustaleniem lub zmianą formuły wątpliwości',
          'przygotowanie propozycji pytań dla stron i świadków',
          'stawiennictwo na publikacji akt',
          'przedłożenie nowych dowodów po publikacji, krytyczne uwagi do opinii biegłego psychologa lub wniosek o powołanie nowego',
          'przygotowanie głosu obrończego po publikacji akt',
          'przygotowanie odpowiedzi na uwagi obrońcy węzła',
          'apelacja do II instancji',
          'prośba o wyznaczenie III instancji w Polsce',
          'apelacja do Roty Rzymskiej (III instancja)',
          'zaskarżenie wyroków dotkniętych wadą usuwalną lub nieusuwalną (querella nullitatis)',
          'Dla stron pozwanych:',
          'konsultacja sprawy po otrzymaniu przez stronę pozwaną skargi powodowej lub informacji o rozpoczęciu procesu',
          'przygotowanie odpowiedzi na skargę powodową',
          'przygotowanie skargi wzajemnej',
          'pomoc w doborze materiału dowodowego',
          'redagowanie pism procesowych związanych z ustaleniem lub zmianą formuły wątpliwości',
          'przygotowanie propozycji pytań dla stron i świadków',
          'stawiennictwo na publikacji akt',
          'przedłożenie nowych dowodów po publikacji, krytyczne uwagi do opinii biegłego psychologa lub wniosek o nowego biegłego',
          'przygotowanie głosu obrończego po publikacji akt',
          'przygotowanie odpowiedzi na uwagi obrońcy węzła',
          'apelacja do II instancji',
          'prośba o wyznaczenie III instancji w Polsce',
          'apelacja do Roty Rzymskiej (III instancja)',
          'zaskarżenie wyroków dotkniętych wadą usuwalną lub nieusuwalną (querella nullitatis)',
        ],
      },
      {
        icon: <PrawoWyznanione />,
        title: 'Prawo Wyznaniowe Prawo Kanoniczne',
        slug: 'prawo-wyznaniowe',
        scopeDetail: [
          'konsultacje i porady prawne',
          'opinie prawne',
          'przygotowywanie i opiniowanie umów zawieranych przez instytucje kościelne',
          'zastępstwo procesowe',
          'doradztwo dla instytucji kościelnych',
          'kompleksowa obsługa prawna diecezji, parafii, zakonów, stowarzyszeń kościelnych, fundacji',
          'obsługa inwestycji i działalności gospodarczej prowadzonej przez instytucje kościelne',
          'obsługa prawna sporów instytucji kościelnych z organami samorządu terytorialnego, kontrahentami, wykonawcami inwestycji kościelnych, najemcami i dzierżawcami nieruchomości kościelnych',
          'doradztwo prawne w zakresie zarządu majątkiem kościelnym',
          'doradztwo prawne dla podmiotów prowadzących cmentarze z zakresu prawa go grobu',
          'prowadzenie spraw z zakresu naruszenia dóbr osobistych instytucji kościelnych, duchownych',
          'prowadzenie spraw karnych z zakresu przestępstw przeciwko wolności sumienia i wyznania',
          'prowadzenie spraw i przygotowywanie opinii z zakresu zachowania kanonów dotyczących zbywania i obciążania mienia stanowiącego własność kościelnych osób prawnych oraz ich skuteczność w prawie polskim',
          'sprawy związane z ochroną zabytków kościelnych',
        ],
      },
    ],
  },
]
