import { theme } from '../assets/theme'
import { GlobalStyle } from '../assets/style'
import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import TopSection from '../sections/TopSection'
import ogImage from '../assets/images/razem2.jpg'
import favicon from '../assets/icons/favicon.png'

const MainTemplate = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Parisienne&display=swap"
          rel="stylesheet"
        />
        <meta property="og:image" content={ogImage} />

        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <TopSection />
        {children}
      </ThemeProvider>
    </>
  )
}
export default MainTemplate
