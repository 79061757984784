import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 100%;
  background-color: ${(props) => props.theme.colors.gold};
  z-index: 100;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (!props.isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  display: flex;
  justify-content: center;
  align-items: center;
`
const CloseIcon = styled.div`
  position: fixed;
  top: 20px;
  right: 35px;
  padding: 1rem;
  &:before,
  :after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.white};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Navigation = styled.nav`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    padding: 0.5rem 1rem;
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    font-size: 1.5rem;
  }
`

const MobileNavigation = ({ isOpen, handleClose }) => {
  return (
    <>
      <Helmet>
        <body style={`overflow: ${isOpen ? 'hidden' : 'auto'}`} />
      </Helmet>
      <Container isOpen={isOpen}>
        <CloseIcon onClick={handleClose} />
        <Navigation>
          <AnchorLink to="/" onAnchorLinkClick={handleClose}>
            Strona główna
          </AnchorLink>
          <AnchorLink to="/#o-nas" onAnchorLinkClick={handleClose}>
            O nas
          </AnchorLink>
          <AnchorLink to="/#uslugi" onAnchorLinkClick={handleClose}>
            Usługi
          </AnchorLink>
          <AnchorLink to="/#kontakt" onAnchorLinkClick={handleClose}>
            Kontakt
          </AnchorLink>
        </Navigation>
      </Container>
    </>
  )
}

export default MobileNavigation
