import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`  
  html {
    box-sizing: border-box;
  }
  
  *, *::after, *::before {
    box-sizing: inherit;
  }

  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
  }

  a, button, textarea {
    font-family: 'Inter', sans-serif;
  }
`
