export const theme = {
  colors: {
    white: '#FFFFFF',
    whiteDarker: '#fafafa',
    black: '#111111',
    darkGrey: 'rgba(0,0,0,0.6)',
    lightGrey: '#f2f2f4',
    grey: '#ececec',
    goldLightest: '#ebd19e',
    goldLightestTransparent: 'rgba(235,209,158,0.15)',
    goldLighter: '#dcc171',
    gold: '#e0b768',
    darkGold: '#A09061',
  },
}
