import React, { useState } from 'react'
import styled from 'styled-components'
import { lawyers } from '../assets/data'
import MobileNavigation from '../components/MobileNavigation'
import DesktopNavigation from '../components/DesktopNavigation'
import useWindowDimensions from '../hooks/useWindowDimensions'
import Bars from '../assets/icons/bars.inline.svg'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
`
const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 1rem 2rem;
  img {
    height: 2.5rem;
  }
`
const NavigationContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
  h3 {
    font-weight: 400;
    // TODO: change to junge font
    text-align: center;
    font-size: 1rem;
    margin: 0;
  }
`
const StyledBars = styled(Bars)`
  padding: 0.4rem 0.2rem;
  width: 30px;
  color: ${({ theme }) => theme.colors.darkGold};
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 100;
`
const TopSection = () => {
  const { width } = useWindowDimensions()
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false)
  const isMobile = width < 768

  const openMenu = () => setIsMobileNavigationOpen(true)
  const closeMenu = () => setIsMobileNavigationOpen(false)

  return (
    <Container>
      <LogoContainer>
        {lawyers.map((lawyer) => (
          <img src={lawyer.logo} alt={lawyer.name} key={lawyer.id} />
        ))}
      </LogoContainer>
      <NavigationContainer>
        {isMobile ? <StyledBars onClick={openMenu} /> : <DesktopNavigation />}
        <h3>
          Kancelaria Adwokacka
          <br />
          Orla 3 20-022 Lublin
        </h3>
      </NavigationContainer>
      {isMobile && <MobileNavigation isOpen={isMobileNavigationOpen} handleClose={closeMenu} />}
    </Container>
  )
}

export default TopSection
