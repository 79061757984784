import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const StyledText = styled(AnchorLink)`
  margin: 0 15px;
  padding: 0;
  text-transform: uppercase;
  transition-duration: 0.5s;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.gold};
`

const DesktopNavigation = () => {
  return (
    <div>
      <StyledText to="/">Strona główna</StyledText>
      <StyledText to="/#o-nas">O nas</StyledText>
      <StyledText to="/#uslugi">Usługi</StyledText>
      <StyledText to="/#kontakt">Kontakt</StyledText>
    </div>
  )
}

export default DesktopNavigation
